import { useState, useEffect } from 'react';
import { storeObject, retrieveObject, getItem } from '../../../config/LocalStorage';

const useUserInfo = () => {
  let [user_info, setUserInfoState] = useState('');
  const [loginCount, setloginCount] = useState(0);
  useEffect(() => {
    let userInfoInLocalStorage = {};
    if (typeof window !== 'undefined') {
      userInfoInLocalStorage = retrieveObject('user');
      setloginCount(getItem('loginCount') ?? 0);
    }
    setUserInfoState(userInfoInLocalStorage);
  }, []);

  let setUserInfo = user => {
    storeObject('user', user);
    setUserInfoState(user);
  };

  return [user_info, setUserInfo, loginCount];
};

export default useUserInfo;
