import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import ClassNames from 'classnames';
import { getGlobalSearchData } from '../../../redux/actions/main';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import SearchInputStickyHeader from '../../packages/search-input-sticky-header';
import { gotoHome } from '../../util';
import classes from './mobFilterCard.module.scss';
import classNames from 'classnames';

function MobSearchFilter(props) {
  const {
    root_class,
    module_type,
    search_filter_home,
    handleOuterClickAwaySearchInput,
    onSelectMultipleCity,
    removeCity,
    selectedCities,
    city,
    popular_part_id,
    locality_id,
    sub_location_id,
    developer_id,
    propertyDetailsClick,
    onClick,
  } = props;
  const [keywords, setKeywords] = useState('');
  const [city_id, setCityId] = useState(city?.id);

  useEffect(() => {
    if (city) {
      setCityId(city?.id);
    }
    return;
  }, [city, popular_part_id]);

  useEffect(() => {
    if (keywords !== '') {
      props.getGlobalSearchData({
        selectedCity: city_id,
        keyword: keywords,
        whereNotPopularPart: popular_part_id,
        whereNotLocality: locality_id,
        whereNotSubLocality: sub_location_id,
        whereNotDeveloper: developer_id,
      });
    }
    return;
  }, [keywords]);

  const { global_search_data } = props.headerApiData;

  const onSearchKeywordChanged = debounce(event => {
    setKeywords(event.target.value);
  }, 500);

  const handleDeleteCity = data => {
    if (removeCity) {
      removeCity(data?.id);
    }
  };

  const onSelectCity = (data, local_storage_name) => {
    if (onSelectMultipleCity) onSelectMultipleCity(data, local_storage_name);
  };

  const handleClick = (e) => {
    if (onClick && typeof onClick === 'function') onClick(e);
  };

  return (<>
    <section className={ClassNames([classes.root, root_class])}>
      <Grid container className={classes.filtersearch}>
        <Grid className={classes.topGrid} size={1}>
          <div
            className={classes.backBtn}
            onClick={
              search_filter_home ? handleOuterClickAwaySearchInput : gotoHome
            }>
            <img
              className="cardNotClicked"
              style={{
                objectFit: 'cover',
                objectPosition: '-25px 10px',
              }}
              src="/static/images/searchPageIco.svg"
              width={25}
              height={37}
              alt="back-icon"
              title="back-icon"
            />
          </div>
        </Grid>
        <Grid className={classNames([classes.borderGrid, classes.topGrid])} size={10}>
          <SearchInputStickyHeader
            className={classes.searchInput}
            is_open={false}
            handleOpen={handleClick}
            handleClickAway={() => {}}
            onSelectSearch={onSelectCity}
            handleChange={onSearchKeywordChanged}
            placeholder="Location"
            width="100%"
            selectedCities={selectedCities}
            homepageSearchBox={global_search_data}
            onSelectMultipleCity={onSelectCity}
            handleDelete={handleDeleteCity}
            propertyDetailsClick={propertyDetailsClick}
            module_type={module_type}
          />
        </Grid>
        <Grid size={1}>
          <button
            onClick={handleClick}
            xs={1}
            className={classNames([classes.filterBtn, classes.topGrid])}>
            <img
              className="cardNotClicked"
              style={{
                objectFit: 'cover',
                objectPosition: '-224px 4px',
              }}
              src="/static/images/searchPageIco.svg"
              width={25}
              height={37}
              alt="back-icon"
              title="back-icon"
            />
          </button>
        </Grid>
      </Grid>
    </section>
  </>);
}

const mapStateToProps = state => ({
  headerApiData: state.headerReducer,
});

const mapDispatchToProps = {
  getGlobalSearchData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobSearchFilter);
