import React, { Fragment, useEffect } from 'react';
import { styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import HomepageSearchBox from '@/components/packages/homepage-search-box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ClassNames from 'classnames';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  show: `${PREFIX}-show`,
  hide: `${PREFIX}-hide`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    boxShadow: 'none',
    '& .MuiInputBase-root': {
      //textAlign: 'left!important',
      width: '100%',
    },

    '& .MuiInputBase-input': {
      fontFamily: 'Open Sans, sans-serif',
      display: 'flex!important',
      color: '#171717',
      fontSize: '13px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        fontSize: '11px',
        paddingLeft: '10px',
      },
    },
  },

  [`& .${classes.show}`]: {
    display: 'block',
    position: 'relative',
  },

  [`& .${classes.hide}`]: {
    display: 'none',
  }
}));

export default function SearchInput(props) {

  const {
    width,
    height,
    style,
    placeholder,
    homepageSearchBox,
    handleChange,
    onSelectSearch,
    selectedCities,
    handleOpen,
    handleClickAway,
    is_open,
    is_mobile,
    propertyDetailsClick,
    keyword,
    module_type,
    name,
    isdisabled=false
  } = props;

  useEffect(() => {
    return;
  }, [homepageSearchBox]);
  return (
    (<Root className={classes.root} square={true}>
      <>
        <InputBase
          placeholder={placeholder}
          width={width}
          height={height}
          style={style}
          onClick={handleOpen}
          onChange={handleChange}
          name={name}
          autoComplete="off"
          readOnly={isdisabled}
        />
        {!is_mobile && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway ? handleClickAway : () => void 0}>
            <div
              className={ClassNames([is_open ? classes.show : classes.hide])}>
              <HomepageSearchBox
                selectedCities={selectedCities}
                popularPartData={homepageSearchBox?.popularPart}
                localitiesData={homepageSearchBox?.localities}
                sublocalitiesData={homepageSearchBox?.sub_localities}
                propertiesData={homepageSearchBox?.properties}
                developersData={homepageSearchBox?.developers}
                close_search={handleClickAway}
                onSelectSearch={onSelectSearch}
                mobile={is_mobile}
                propertyDetailsClick={propertyDetailsClick}
                module_type={module_type}
                keyword={keyword}
              />
            </div>
          </ClickAwayListener>
        )}
      </>
    </Root>)
  );
}
