import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Section from '../../Section';
import debounce from 'lodash/debounce';

import {
  List,
  ListItem,
  ListItemText,
  Typography,
  // withStyles,
} from '@mui/material';

import axios from '../../../../../redux/actions/axios-instance-config';
import { API_ROOT } from '../../../../../config';
import { GLOBAL_SEARCH_DATA_API } from '../../../../../config/EndpointHelper';

const PREFIX = 'SearchSuggestions';

const classes = {
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
  suggessionItemText: `${PREFIX}-secondary`
};

const StyledSection = styled(Section)({
  [`& .${classes.primary}`]: {
    fontSize: '0.9rem',
  },
  [`& .${classes.secondary}`]: {
    fontSize: '0.8rem',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  [`& .${classes.suggessionItemText}`]: {
    display: 'flex!important',
    flexFlow: 'row nowrap!important',
    justifyContent: 'space-between!important',
  },
});

const StyledListItemText = ListItemText;

const TypeNameMap = {
  popularPart: 'Popular Part',
  localities: 'Popular Locality',
  sub_localities: 'Sub Location',
  properties: 'Popular Projects',
};

const fetchSuggestions = debounce(
  async (city, keyword, exclusions, onResponse) => {
    const response = await axios.get(`${API_ROOT}${GLOBAL_SEARCH_DATA_API}`, {
      params: {
        selectedCity: city.id,
        keyword: keyword,
        whereNotPopularPart: exclusions.parts.map(item => item.id),
        whereNotLocality: exclusions.localities.map(item => item.id),
        whereNotSubLocality: exclusions.subLocalities.map(item => item.id),
      },
    });

    if (onResponse && typeof onResponse === 'function') {
      onResponse(response);
    }
  },
  400,
  { leading: false }
);

function SearchSuggestions(props) {
  const { rootClass, city, keyword, exclusions, onItemClick } = props;

  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const parts = exclusions.filter(item => item.type === 'popularPart');
  const localities = exclusions.filter(item => item.type === 'localities');
  const subLocalities = exclusions.filter(
    item => item.type === 'sub_localities'
  );

  useEffect(() => {
    setLoading(true);
    fetchSuggestions(
      city,
      keyword,
      { parts, localities, subLocalities },
      response => {
        if (response.status === 200) {
          const data = response.data.data;
          delete data.developers;
          const suggestionsLocality = Object.keys(data)
            .map(key => data[key].map(item => ({ ...item, type: key })))
            .flat();
          setSuggestions(suggestionsLocality);
        }
        setLoading(false);
      }
    );
  }, [city, keyword, exclusions]);

  return (
    (<StyledSection rootClass={rootClass}>
      {suggestions.length > 0 && (
        <List disablePadding={true} aria-label="search suggestions">
          {suggestions.map(({ id, name, type, localityName }) => (
            <ListItem
              onClick={() =>
                onItemClick &&
                typeof onItemClick === 'function' &&
                onItemClick(id, name, type, localityName)
              }
              key={`${type}-${id}`}
              className={classes.suggessionItem}
              button
              divider>
              <StyledListItemText
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
                className={classes.suggessionItemText}
                primary={name}
                secondary={TypeNameMap[type]}
                classes={{
                  primary: classes.primary,
                  secondary: classes.secondary,
                  multiline: {
                    fontSize: '0.9rem',
                  },
                }} />
            </ListItem>
          ))}
        </List>
      )}
      {!loading && suggestions.length === 0 && (
        <Typography className={classes.message} variant="body2">
          Sorry, no matching results found
        </Typography>
      )}
    </StyledSection>)
  );
}

export default SearchSuggestions;
